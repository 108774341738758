body{font-family: 'Poppins', sans-serif; background-color:#F5F5F5;}
.fs-9{font-size:.9em !important;}
.fs-8{font-size:.8em !important;}
ul.footer-menu{list-style:none !important;}
ul.footer-menu li{margin-bottom:10px;}
.wa_click_to_chat{ position:fixed;bottom:15px;right:30px; background-color:black; width:70px;height:70px; 
border-radius:50%;padding:5px;  background-color:white;box-shadow:0 1px 4px 0 rgba(0,0,0,.14);
 }
  .wa_click_to_chat div{position:relative; }
  .wa_live_indicator{
    display:block; 
    background-color:red;
    position:absolute;
    right:0px;
    top:-5px;
    width:18px;
    height:18px;
    border-radius:50%;
    animation-name:blinker;
    color:white;
    cursor:pointer;
    font-size:.9em;
    animation-duration:2s;
    /* animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); */
    animation-iteration-count: infinite;
    /* transition:0.5s; */
  }

  .main-background-cover{
    background-image: url('../img/default_img/abstract.jpg');
    height:400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
  }

  .custom-inline-listing ul{
    margin:0px;
    padding:0px;
  }

  .custom-inline-listing li{
    display: inline-block;
    margin-right:30px;
  }


  .custom-overlay{
    position:absolute;
    top:0px;
    right:0px;
    left:0px;
    bottom:0px;
    background-color:rgba(0,0,0,.7);
  }

  .edatsu_bg_color{background-color:#FFE01B;}
  .footer_bg_color{background-color:#000000; color:white !important;}
 